/* eslint-disable react-hooks/rules-of-hooks */
import { Picture } from '@components/ui'
import SectionTitle from '@components/layout/SectionTitle'
import styles from './AdvantagePowerHouse.module.css'
import cn from 'classnames'
import { motion, LayoutGroup, useScroll, useTransform } from 'framer-motion'
import { useRef } from 'react'
import Button from '@components/control/Button'
import SolixButton from '@components/control/SolixButton'
import { useRelativeLink } from '@commerce/product/use-relative-link'
const AdvantagePowerHouse = ({ data, pageType }) => {
  const { setRelativeLink } = useRelativeLink()
  const refAdvantage = useRef(null)
  let itemVariants = {
    show: {
      x: 0,
      transition: { duration: 0.3 },
    },
    hidden: {
      x: '-120%',
      transition: { duration: 0.3 },
    },
  }
  const { scrollYProgress } = useScroll({
    target: refAdvantage,
    offset: ['start end', 'end center'],
  })
  const ref = useRef(null)
  const { scrollXProgress } = useScroll({
    container: ref,
  })
  return (
    <div className={cn(styles.root, 'overflow-hidden')}>
      {data?.lastHide && (
        <style jsx global>
          {`
            @media (max-width: 767px) {
              .${styles.advantage_box}:last-child {
                display: none;
              }
            }
          `}
        </style>
      )}
      <SectionTitle
        subTitle={data?.subTitle}
        title={data?.title}
        text={data?.text}
        className={styles.title_box}
        cns={{
          subTitle: data.title_left ? styles.subTitle_left : styles.subTitle,
        }}
        jumpOver={data?.jumpOver}
        blogLink={data?.blogLink}
      />
      <section
        className={cn(
          styles.advantage_layer,
          { [styles.advantage_layer_padding]: data?.haspadding },
          { [styles.advantage_layer_mobIsSwiper]: data?.mobIsSwiper },
          'layer'
        )}
      >
        <motion.ul
          ref={ref}
          layout
          className={cn(styles.advantage_content, 'content', {
            [styles.advantage_content_flex]: data?.mobIsSwiper,
          })}
        >
          {data?.content &&
            data?.content.map((v, i) => {
              const link =
                setRelativeLink({ link: v?.link }) +
                '?ref=homePageBanner' +
                (pageType === 'powerStation' || pageType === 'hes'
                  ? '_pps'
                  : '')
              return (
                <motion.li
                  layout
                  key={`advantage-${i}`}
                  ref={refAdvantage}
                  className={cn(styles.advantage_box, {
                    [styles.advantage_onlyOne]: v.onlyOne,
                    [styles.advantage_onlyOne_left]: v?.imgLeft,
                    [styles.advantage_boxWidth]: data.mobIsSwiper,
                  })}
                  onClick={() => {
                    window.location.href = link
                  }}
                  initial="hidden"
                  whileInView="show"
                  viewport={{ once: true, amount: 0.6 }}
                  variants={{
                    show: {
                      transition: {
                        duration: 0.5,
                        staggerChildren: 0.05,
                      },
                    },
                    hidden: {
                      transition: {
                        duration: 0.5,
                      },
                    },
                  }}
                >
                  <motion.div
                    layout
                    className={cn(
                      styles.advantage_flex,
                      {
                        [styles.advantage_flex_onlyOne]: v.onlyOne,
                        [styles.advantage_flex_onlyOne_left]:
                          i % 2 == 0 && !data.mobIsSwiper,
                        [' md:hidden']: data.mobIsSwiper,
                      },
                      'flex'
                    )}
                  >
                    <motion.div
                      layout
                      className={cn(styles.advantage_textAll, {
                        [styles.advantage_textAllOnlyOne]: v.onlyOne,
                        [styles.advantage_textAllOnlyOne_left]: v?.imgLeft,
                        [styles.advantage_textAll_mobIsSwiper]:
                          data.mobIsSwiper,
                      })}
                    >
                      <motion.div
                        layout
                        className={cn(styles.advantage_title_box, {
                          [styles.advantage_titleOnlyOne_box]: v.onlyOne,
                        })}
                      >
                        <motion.div className="md:px-[24px]">
                          <motion.div className="md_content_grid">
                            {v?.subTitle && (
                              <motion.p
                                variants={itemVariants}
                                layout
                                className={styles.advantage_subTitle}
                                dangerouslySetInnerHTML={{
                                  __html: v?.subTitle,
                                }}
                              />
                            )}
                            <motion.div
                              className={cn(
                                styles.advantage_title_grid,
                                'md_content_grid',
                                {
                                  [styles.advantage_titleOnlyOne_grid]:
                                    v.onlyOne,
                                }
                              )}
                            >
                              {v?.title &&
                                v?.title.map((item, index) => (
                                  <motion.p
                                    layout
                                    key={`advantagetitle-${index}`}
                                    variants={itemVariants}
                                    className={cn(styles.advantage_title, {
                                      [styles.advantage_titleOnlyOne]:
                                        v.onlyOne,
                                    })}
                                    dangerouslySetInnerHTML={{
                                      __html: item?.title,
                                    }}
                                  />
                                ))}
                            </motion.div>
                            {v?.text && (
                              <motion.p
                                variants={itemVariants}
                                layout
                                className={styles.advantage_text}
                                dangerouslySetInnerHTML={{
                                  __html: v?.text,
                                }}
                              />
                            )}
                            <motion.p
                              variants={itemVariants}
                              layout
                              className={cn(styles.advantage_a_box, {
                                [styles.advantage_a_box_onlyOne]: v.onlyOne,
                              })}
                            >
                              {data.isBtn ? (
                                <>
                                  {pageType === 'powerStation' ||
                                  pageType === 'hes' ? (
                                    <>
                                      <SolixButton className="md:!max-w-[unset]">
                                        <motion.a
                                          className={cn(
                                            styles.advantage_a,
                                            styles.advantageActive
                                          )}
                                          layout
                                          href={link}
                                          target="_self"
                                          dangerouslySetInnerHTML={{
                                            __html: v?.learnMore,
                                          }}
                                        />
                                      </SolixButton>
                                    </>
                                  ) : (
                                    <Button className="md:!max-w-[unset]">
                                      <motion.a
                                        className={cn(
                                          styles.advantage_a,
                                          styles.advantageActive
                                        )}
                                        layout
                                        href={link}
                                        target="_self"
                                        dangerouslySetInnerHTML={{
                                          __html: v?.learnMore,
                                        }}
                                      />
                                    </Button>
                                  )}
                                </>
                              ) : (
                                <motion.a
                                  className={styles.advantage_a}
                                  layout
                                  href={link}
                                  target="_self"
                                  dangerouslySetInnerHTML={{
                                    __html: v?.learnMore,
                                  }}
                                />
                              )}
                            </motion.p>
                          </motion.div>
                        </motion.div>
                      </motion.div>
                    </motion.div>
                    <motion.div className={styles.advantage_img_box}>
                      <Picture
                        source={`${v?.img},${v?.img_mob} 768`}
                        className={cn(
                          styles.advantage_img,
                          { ['aspect-w-[740] aspect-h-[370]']: v.onlyOne },
                          { ['aspect-w-[416] aspect-h-[240]']: !v.onlyOne },
                          'md:aspect-w-[342] ',
                          'md:aspect-h-[200]'
                        )}
                      />
                    </motion.div>
                  </motion.div>
                  {data.mobIsSwiper && (
                    <motion.div
                      layout
                      className={cn(styles.advantage_flex, {
                        [styles.advantage_flex_onlyOne]: v.onlyOne,
                        [styles.advantage_flex_onlyOne_left]: v?.imgLeft,
                        ['hidden md:flex']: data.mobIsSwiper,
                      })}
                    >
                      <motion.div
                        layout
                        className={cn(styles.advantage_textAll, {
                          [styles.advantage_textAllOnlyOne]: v.onlyOne,
                          [styles.advantage_textAllOnlyOne_left]: v?.imgLeft,
                          [styles.advantage_textAll_mobIsSwiper]:
                            data.mobIsSwiper,
                        })}
                      >
                        <motion.div
                          layout
                          className={cn(styles.advantage_title_box, {
                            [styles.advantage_titleOnlyOne_box]: v.onlyOne,
                          })}
                        >
                          <motion.div className="md:px-[24px]">
                            <motion.div className="md_content_grid">
                              {v?.subTitle && (
                                <motion.p
                                  layout
                                  className={styles.advantage_subTitle}
                                  dangerouslySetInnerHTML={{
                                    __html: v?.subTitle,
                                  }}
                                />
                              )}
                              <motion.div
                                className={cn(
                                  styles.advantage_title_grid,
                                  'md_content_grid',
                                  {
                                    [styles.advantage_titleOnlyOne_grid]:
                                      v.onlyOne,
                                  }
                                )}
                              >
                                {v?.title &&
                                  v?.title.map((item, index) => (
                                    <motion.p
                                      layout
                                      key={`advantagetitle-${index}`}
                                      className={cn(styles.advantage_title, {
                                        [styles.advantage_titleOnlyOne]:
                                          v.onlyOne,
                                      })}
                                      dangerouslySetInnerHTML={{
                                        __html: item?.title,
                                      }}
                                    />
                                  ))}
                              </motion.div>
                              {v?.text && (
                                <motion.p
                                  layout
                                  className={styles.advantage_text}
                                  dangerouslySetInnerHTML={{
                                    __html: v?.text,
                                  }}
                                />
                              )}
                              <motion.p
                                layout
                                className={cn(styles.advantage_a_box, {
                                  [styles.advantage_a_box_onlyOne]: v.onlyOne,
                                })}
                              >
                                <motion.a
                                  className={styles.advantage_a}
                                  layout
                                  href={link}
                                  target="_self"
                                  dangerouslySetInnerHTML={{
                                    __html: v?.learnMore,
                                  }}
                                />
                              </motion.p>
                            </motion.div>
                          </motion.div>
                        </motion.div>
                      </motion.div>
                      <motion.div className={styles.advantage_img_box}>
                        <Picture
                          source={`${v?.img},${v?.img_mob} 768`}
                          className={cn(
                            styles.advantage_img,
                            { ['aspect-w-[740] aspect-h-[370]']: v.onlyOne },
                            { ['aspect-w-[416] aspect-h-[240]']: !v.onlyOne },
                            'md:aspect-w-[283] ',
                            'md:aspect-h-[165]'
                          )}
                        />
                      </motion.div>
                    </motion.div>
                  )}
                </motion.li>
              )
            })}
        </motion.ul>
        {data.mobIsSwiper && (
          <div className=" relative col-span-layer-12-full m-auto mt-[25px] hidden h-[4px] w-[100px] rounded-[4px] bg-[#E5E5E5] md:block">
            <motion.div
              style={{
                width: useTransform(scrollXProgress, [0, 1], ['0%', '100%']),
              }}
              className="absolute  left-0 top-0 h-full w-full rounded-l-[10px] rounded-r-[10px] bg-[#00A9E1]"
            ></motion.div>
          </div>
        )}
      </section>
    </div>
  )
}
export default AdvantagePowerHouse

import cn from 'classnames'
import React from 'react'
import Image from 'next/image'
import Button from '@components/control/Button'
import SolixButton from '@components/control/SolixButton'

import SolixTitle from '../SolixTitle'
import { useRelativeLink } from '@commerce/product/use-relative-link'

import s from './ProductSeries.module.css'

import { motion } from 'framer-motion'

const ProductSeries = ({
  data = {},
  dataKey,
  pageType = 'pps',
  gs = {},
  linkType,
  motionFlag = false,
}) => {
  const { seriesList } = data
  const { setRelativeLink } = useRelativeLink()

  return (
    <div className={s.ProductSeries} style={data?.styles} id={dataKey}>
      <div className="layer">
        <div className="content">
          <div className={s.titleBox}>
            {pageType == 'charger' && (
              <>
                {data?.title &&
                  (motionFlag ? (
                    <motion.div
                      initial={{ y: '20%', opacity: 0 }}
                      whileInView={{
                        y: 0,
                        opacity: 1,
                      }}
                      transition={{
                        duration: 1,
                        ease: 'easeOut',
                      }}
                      className={cn(s.chargeTitle, gs?.chargeTitle)}
                      dangerouslySetInnerHTML={{
                        __html: data.title,
                      }}
                    ></motion.div>
                  ) : (
                    <h2
                      className={cn(s.chargeTitle, gs?.chargeTitle)}
                      dangerouslySetInnerHTML={{
                        __html: data.title,
                      }}
                    />
                  ))}
                {data?.subTitle && (
                  <p
                    className={s.chargeSubTitle}
                    dangerouslySetInnerHTML={{
                      __html: data.subTitle,
                    }}
                  />
                )}
              </>
            )}

            {pageType == 'pps' && (
              <SolixTitle
                cs={{
                  title: cn(s.sectionTitle, gs?.sectionTitle),
                  subTitle: s.sectionSubTitle,
                }}
                title={data.title}
                subTitle={data.subTitle}
              />
            )}
          </div>
          <div
            className={cn(cn(s.seriesBox, 'ProductSeriesSeriesBox'), {
              ['!pt-[32px] l:!pt-[20px]']: `${pageType} == 'charger'`,
            })}
          >
            {seriesList?.map((item, i) => {
              const link = `${setRelativeLink({ link: item?.href })}${
                data?.ref || ''
              }`
              return (
                <div
                  key={`${item?.title}-${i}`}
                  className={cn(s.card, gs?.card)}
                >
                  <div className={s.content}>
                    <p
                      className={cn(s.title, gs?.title)}
                      style={{ color: item?.textColor }}
                      dangerouslySetInnerHTML={{
                        __html: item?.title,
                      }}
                    />
                    {item?.subTitle && (
                      <p
                        className={cn(s.subTitle, gs?.subTitle)}
                        style={{ color: item?.textColor }}
                        dangerouslySetInnerHTML={{
                          __html: item?.subTitle,
                        }}
                      />
                    )}

                    {pageType == 'pps' && (
                      <SolixButton
                        variant="link"
                        href={link}
                        alt=""
                        className={s.learnmore}
                        onClick={() => {
                          if (data?.fbq && window.fbq) {
                            fbq('trackCustom', data?.fbq)
                          }
                        }}
                      >
                        {item?.learnmore}
                      </SolixButton>
                    )}

                    {pageType == 'charger' &&
                      (linkType == 'aTag' ? (
                        <div className="flex items-center justify-center gap-[10px]">
                          <a
                            style={{
                              color: item?.linkTextColor || item?.textColor,
                            }}
                            className="text-[20px] font-[500] md:text-[18px]"
                            href={link}
                            target="_self"
                            dangerouslySetInnerHTML={{
                              __html: item?.learnmore,
                            }}
                            onClick={() => {
                              if (data?.fbq && window.fbq) {
                                fbq('trackCustom', data?.fbq)
                              }
                            }}
                          />
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="9"
                            height="15"
                            viewBox="0 0 9 15"
                            fill="none"
                          >
                            <path
                              style={{
                                stroke: item?.linkTextColor || item?.textColor,
                              }}
                              d="M1.12386 1.63379L7.43311 7.94304L1.12386 14.2523"
                              stroke="black"
                              stroke-width="1.33848"
                              stroke-linecap="round"
                            />
                          </svg>
                        </div>
                      ) : (
                        <Button className="mx-auto !max-w-[200px] md:!max-w-[unset]">
                          <a
                            className="flex w-full justify-center"
                            href={link}
                            target="_self"
                            dangerouslySetInnerHTML={{
                              __html: item?.learnmore,
                            }}
                            onClick={() => {
                              if (data?.fbq && window.fbq) {
                                fbq('trackCustom', data?.fbq)
                              }
                            }}
                          />
                        </Button>
                      ))}
                  </div>
                  <Image
                    className={s.cardBackImg}
                    loading="lazy"
                    src={item?.img}
                    alt={item?.title}
                    width={data?.width || 832}
                    height={data?.height || 1520}
                  />
                </div>
              )
            })}
          </div>
        </div>
      </div>
    </div>
  )
}

export default ProductSeries

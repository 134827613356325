/* eslint-disable react-hooks/rules-of-hooks */
import cn from 'classnames'
import { useState, useEffect, useRef, Fragment } from 'react'
import Image from 'next/image'
import { useRouter } from 'next/router'
import { useAddItem } from 'lib/shopify/api/cart'
import { Text, Picture, useUI } from '@components/ui'
import { motion, LayoutGroup, useScroll, useTransform } from 'framer-motion'
import Button from '@components/control/Button'
import { BRAND } from '@shopify/const'
import s from './HomeBanner.module.css'
import { once } from 'events'
import SolixButton from '@components/control/SolixButton'
import { useTimer } from 'react-timer-hook'
import { useRelativeLink } from '@commerce/product/use-relative-link'
const HomeBanner = ({
  page,
  lang = {},
  product,
  gtmRef,
  id,
  pageType,
  customDeviceSizes = null,
  callComponent = null,
}) => {
  const router = useRouter()
  const { openSidebar } = useUI()
  const refBanner = useRef(null)
  const [isMobile, setIsMobile] = useState(false)
  const [loading, setLoading] = useState(false)
  const { setRelativeLink } = useRelativeLink()
  const addItem = useAddItem()
  const { scrollYProgress } = useScroll({
    target: refBanner,
    offset: ['1 1', '3 1'],
  })
  const useParallax = (value, distance) => {
    return useTransform(value, [0, 1], [0, distance])
  }
  const getVariant = ({ product, sku }) => {
    return product?.variants?.find((item) => item?.sku === sku)
  }
  const downloadICSFile = () => {
    const icsContent = `BEGIN:VCALENDAR
VERSION:2.0
PRODID:-//My Calendar//EN
BEGIN:VEVENT
UID:${Math.random()}@mycalendar.com
DTSTAMP:${new Date().toISOString().replace(/-|:|\.\d+/g, '')}
DTSTART:${lang.startTime}
DTEND:${lang.endTime}
SUMMARY:${lang.calendarTitle} 
DESCRIPTION:${lang.calendarDescribe}
LOCATION:${lang.location}
END:VEVENT
END:VCALENDAR`
    const blob = new Blob([icsContent], { type: 'text/calendar' })
    const url = URL.createObjectURL(blob)
    const link = document.createElement('a')
    link.href = url
    link.setAttribute('download', 'calendar.ics')
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
    URL.revokeObjectURL(url)
  }

  const addToCart = async ({ product, sku }) => {
    const variant = getVariant({ product, sku })
    setLoading(true)
    try {
      await addItem({
        variantId: String(variant?.id || product.variants?.[0]?.id),
        quantity: 1,
      })
      openSidebar()
      setLoading(false)
    } catch (err) {
      setLoading(false)
    }
    return variant || product.variants?.[0]
  }

  const [videoId, setVideoId] = useState('')
  const [posterImg, setPosterImg] = useState('')
  const [useVideo, setUseVideo] = useState(false)
  const [curTheme, setCurTheme] = useState('')

  function getTheme() {
    if (window?.innerWidth < 768) {
      setCurTheme(lang?.mobileTheme || lang?.theme)
    } else {
      setCurTheme(lang?.theme)
    }
  }

  useEffect(() => {
    getTheme()
    window.addEventListener('resize', getTheme)
    return () => {
      window.removeEventListener('resize', getTheme)
    }
  }, [])

  const resizeWindow = () => {
    const offsetWidth = document?.querySelector('body')?.offsetWidth || 0
    if (offsetWidth >= 1920) {
      setVideoId(lang?.video)
      setPosterImg(lang?.bgUrl)
      if (lang?.video) setUseVideo(true)
    } else if (offsetWidth >= 1440) {
      setVideoId(lang?.video2 || lang?.video)
      setPosterImg(lang?.bgUrl2 || lang?.bgUrl)
      if (lang?.video2 || lang?.video) setUseVideo(true)
    } else if (offsetWidth >= 768) {
      setVideoId(lang?.video3 || lang?.video)
      setPosterImg(lang?.bgUrl3 || lang?.bgUrl)
      if (lang?.video3 || lang?.video) setUseVideo(true)
    } else {
      setVideoId(lang?.video_mob)
      if (lang?.video_mob) setUseVideo(true)
      setPosterImg(lang?.bgMobileUrl || lang?.bgUrl)
      return setIsMobile(true)
    }
    setIsMobile(false)
  }

  useEffect(() => {
    resizeWindow()
    window.addEventListener('resize', resizeWindow)
    return () => {
      window.removeEventListener('resize', resizeWindow)
    }
  }, [])

  const itemVariants = {
    show: {
      x: 0,
      transition: { duration: 0.3 },
    },
    hidden: { x: '-100%', transition: { duration: 0.3 } },
  }

  return (
    <div
      style={{
        background: lang?.bgColor || 'transparent',
      }}
      className={cn(
        s.root,
        `${gtmRef}`,
        {
          [s.hasMargin]: lang?.hasMargin,
          [s.rootNocur]: !lang?.learnmore,
        },
        `${lang?.abtest ? 'hidden' : 'block'}`
      )}
      ref={refBanner}
      id={id}
      onClick={() => {
        !lang?.buy &&
          lang?.learnmore &&
          (window.location.href = `${lang?.url}?ref=${gtmRef || ''}`)
      }}
    >
      <motion.div
        className={cn(
          callComponent === 'HesSwiperBanner' ? s.bgImgHes : s.bgImg
        )}
        initial="hidden"
        whileInView="show"
        viewport={{ once: true, amount: 0.4 }}
        variants={{
          show: {
            scale: 1,
            transition: {
              duration: 0.3,
            },
          },
          hidden: {
            scale: 1,
            transition: {
              duration: 0.3,
            },
          },
        }}
      >
        {lang?.video ? (
          <div className={s.videoBox}>
            {useVideo ? (
              <video
                poster={posterImg}
                preload="none"
                playsInline
                muted
                autoPlay
                loop={lang?.loop || 'true'}
                src={videoId}
              ></video>
            ) : (
              <Picture
                source={`
                ${lang?.bgUrl}, 
                ${lang?.bgUrl2} 1920,
                ${lang?.bgUrl3} 1440,
                ${lang?.bgMobileUrl || lang?.bgUrl} 768
              `}
                customDeviceSizes={customDeviceSizes}
                alt={lang?.title?.join()}
                loading="eager"
              />
            )}
          </div>
        ) : (
          <Picture
            source={`
            ${lang?.bgUrl}, 
            ${lang?.bgUrl2} 1920,
            ${lang?.bgUrl3} 1440,
            ${lang?.bgMobileUrl || lang?.bgUrl} 768
          `}
            customDeviceSizes={customDeviceSizes}
            alt={lang?.title?.join()}
            loading="eager"
          />
        )}
      </motion.div>

      <motion.div
        className={cn(s.layer, 'layer content-center', {
          ['content-start']: lang?.txtPosition === 'center',
          [s.hesLayer]: callComponent === 'HesSwiperBanner',
        })}
        initial="hidden"
        whileInView="show"
        viewport={{ once: true, amount: 0.6 }}
        variants={{
          show: {
            transition: {
              duration: 0.3,
              staggerChildren: 0.05,
            },
          },
          hidden: {
            transition: {
              duration: 0.3,
            },
          },
        }}
      >
        {lang?.bgTxtMobileUrl && (
          <div className={cn('only-in-mobile', s.mobileBg)}>
            <Picture source={lang?.bgTxtMobileUrl} alt="background img" />
          </div>
        )}
        <motion.div
          className={cn(s.content, 'content', {
            [s.dark]: curTheme?.toLowerCase() === 'dark',
            [s.rightTxt]: lang?.txtPosition === 'right',
            [s.centerTxt]: lang?.txtPosition === 'center',
            ['hidden md:block']: lang?.txtOnlyInMob,
          })}
          style={{
            ...{ y: useParallax(scrollYProgress, isMobile ? 0 : '15%') },
            ...(lang?.mobileTxtColor &&
              isMobile && { color: lang?.mobileTxtColor }),
          }}
        >
          {lang?.lightTitle?.length > 0 && (
            <div
              key={`homeBanner-lightTitle-${gtmRef}`}
              className={s.lightTitle}
            >
              {lang?.lightTitle?.map((item, i) => {
                return (
                  <Fragment key={`lightTitle-${item}-${i}`}>
                    <motion.span
                      layout
                      key={`lightTitle-${item}${i}`}
                      variants={itemVariants}
                      style={{
                        color: lang?.lightColor,
                      }}
                      className={cn(s.wrap, {
                        'block w-full': lang?.txtPosition === 'center',
                      })}
                      dangerouslySetInnerHTML={{ __html: item }}
                    />
                    {i < lang?.lightTitle?.length - 1 && (
                      <span className="wrap only-in-pc" />
                    )}
                  </Fragment>
                )
              })}
            </div>
          )}
          {lang?.title?.length > 0 && (
            <div key={`homeBanner-title-${gtmRef}`} className={s.title}>
              {lang?.title?.map((item, i) => {
                return (
                  <>
                    <motion.span
                      layout
                      key={`title-${item}${i}`}
                      variants={itemVariants}
                      className={cn(s.wrap, {
                        'block w-full': lang?.txtPosition === 'center',
                      })}
                      dangerouslySetInnerHTML={{ __html: item }}
                    />
                    {i < lang?.title?.length - 1 && (
                      <span
                        className="wrap only-in-pc"
                        key={`title-wrap-${item}${i}`}
                      />
                    )}
                  </>
                )
              })}
            </div>
          )}
          {lang?.subTitle?.length > 0 && (
            <div key={`homeBanner-subTitle-${gtmRef}`} className={s.subTitle}>
              {lang?.subTitle?.map((item, i) => {
                return (
                  <>
                    <motion.span
                      layout
                      key={`subTitle-${item}${i}`}
                      variants={itemVariants}
                      className={cn(s.wrap, {
                        'block w-full': lang?.txtPosition === 'center',
                      })}
                      dangerouslySetInnerHTML={{ __html: item }}
                    />
                    {i < lang?.subTitle?.length - 1 && (
                      <span
                        key={`title-wrap-${item}${i}`}
                        className="wrap only-in-pc"
                      />
                    )}
                  </>
                )
              })}
            </div>
          )}
          {lang?.txtPosition != 'center' && <span className={s.gap}></span>}
          {lang?.countDown && lang?.countDown?.endTime && (
            <CountDown
              data={lang?.countDown}
              isMobile={isMobile}
              lang={lang}
              itemVariants={itemVariants}
              curTheme={curTheme}
            />
          )}
          {lang?.description?.length > 0 && (
            <div
              key={`homeBanner-description-${gtmRef}`}
              className={s.description}
            >
              {lang?.description?.map((item, i) => {
                return (
                  <Fragment key={`description-${item}-${i}`}>
                    <motion.span
                      layout
                      key={`description-${item}${i}`}
                      variants={itemVariants}
                      className={cn(s.wrap, {
                        'block w-full': lang?.txtPosition === 'center',
                      })}
                      dangerouslySetInnerHTML={{ __html: item }}
                    />
                    {i < lang?.description?.length - 1 && (
                      <span className="wrap only-in-pc" />
                    )}
                  </Fragment>
                )
              })}
            </div>
          )}

          <motion.div layout variants={itemVariants} className={s.actionBox}>
            {lang?.addToCalendar && (
              <SolixButton
                onClick={() => {
                  downloadICSFile()
                }}
              >
                {lang?.addToCalendar}
              </SolixButton>
            )}
            {lang?.learnmore && (
              <>
                {pageType === 'powerStation' || pageType === 'hes' ? (
                  <>
                    <SolixButton>
                      <a
                        className="block"
                        href={`${setRelativeLink({ link: lang?.url })}?ref=${
                          gtmRef || ''
                        }`}
                      >
                        {lang?.learnmore}
                        <span className="hidden">{gtmRef}</span>
                      </a>
                    </SolixButton>
                  </>
                ) : (
                  <Button
                    className={cn(s.learnmore, {
                      [s.btn]: !lang?.buy,
                    })}
                  >
                    <a
                      className="block"
                      href={`${setRelativeLink({ link: lang?.url })}?ref=${
                        gtmRef || ''
                      }`}
                    >
                      {lang?.learnmore}
                      <span className="hidden">{gtmRef}</span>
                    </a>
                  </Button>
                )}
              </>
            )}
            {lang?.buy && lang?.handle && (
              <Button
                border
                small
                theme={curTheme}
                className={s.buy}
                loading={loading}
                onClick={() => {
                  router.push({
                    pathname: lang?.handle,
                    query: { ref: gtmRef || '' },
                  })
                }}
              >
                {lang?.buy}
              </Button>
            )}
            {lang?.apple_accessories_href && (
              <a
                dangerouslySetInnerHTML={{
                  __html: lang?.apple_accessories_txt,
                }}
                className="relative z-[2] flex items-center text-[14px] font-semibold text-[#00A9E1]"
                href={setRelativeLink({ link: lang?.apple_accessories_href })}
              ></a>
            )}
          </motion.div>
        </motion.div>
      </motion.div>
    </div>
  )
}

export default HomeBanner

const CountDown = ({ data, itemVariants, lang, isMobile, curTheme }) => {
  if (!data || !data.endTime || new Date() > new Date(data.endTime)) return null
  const { seconds, minutes, hours, days } = useTimer({
    expiryTimestamp: new Date(data.endTime),
  })

  return (
    <motion.div
      variants={itemVariants}
      className={cn('flex md:justify-center', {
        ['md:justify-start']: data?.justifyContent === 'left',
        ['md:justify-end']: data?.justifyContent === 'right',
      })}
    >
      {!data.hideDays && !data.countDown24 && (
        <Item
          time={days}
          copy={data.days}
          data={data}
          lang={lang}
          isMobile={isMobile}
          curTheme={curTheme}
        />
      )}
      <Item
        time={data.hideDays ? days * 24 + hours : hours}
        isMobile={isMobile}
        copy={data.hours}
        data={data}
        lang={lang}
        curTheme={curTheme}
      />
      <Item
        time={minutes}
        copy={data.minutes}
        data={data}
        lang={lang}
        isMobile={isMobile}
        curTheme={curTheme}
      />
      <Item
        time={seconds}
        copy={data.seconds}
        last={true}
        data={data}
        lang={lang}
        isMobile={isMobile}
        curTheme={curTheme}
      />
    </motion.div>
  )
}

const Item = ({ time, copy, data, lang, last = false, isMobile, curTheme }) => (
  <div className="flex flex-col items-center">
    <div className="flex items-center">
      <div
        style={{
          ...data?.styles,
          ...(lang?.mobileTxtColor &&
            isMobile && { color: lang?.mobileTxtColor }),
        }}
        suppressHydrationWarning={true}
        className={cn(
          'mt-[2px] flex h-[50px] w-[50px] items-center justify-center rounded-[4px] bg-[#FBA929] text-[30px] font-semibold text-white md:h-[45px] md:w-[45px] md:text-[18px]',
          { [s.dark]: curTheme?.toLowerCase() === 'dark' }
        )}
      >
        {time < 10 ? `0${time}` : time}
      </div>
      {!last && (
        <span
          style={{
            ...(lang?.mobileTxtColor &&
              isMobile && { color: lang?.mobileTxtColor }),
          }}
          className={cn('px-1 text-[30px] text-[#FBA929] md:text-[10px]', {
            [s.dark]: curTheme?.toLowerCase() === 'dark',
          })}
        >
          :
        </span>
      )}
    </div>
    <div
      dangerouslySetInnerHTML={{
        __html: copy,
      }}
      style={{
        ...(lang?.mobileTxtColor &&
          isMobile && { color: lang?.mobileTxtColor }),
      }}
      className={cn(
        'pt-[6px] text-[14px] text-black md:text-[13px]',
        {
          ['pr-2 md:pr-1']: !last,
        },
        { [s.dark]: curTheme?.toLowerCase() === 'dark' }
      )}
    ></div>
  </div>
)

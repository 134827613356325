import { Picture } from '@components/ui'
import styles from './Media.module.css'
import cn from 'classnames'
import { useEffect, useState } from 'react'
import SectionTitle from '@components/layout/SectionTitle'
import { Swiper, SwiperSlide } from 'swiper/react'
import { Navigation, Pagination, Autoplay } from 'swiper/modules'
import 'swiper/css'
import 'swiper/css/navigation'
import 'swiper/css/pagination'

const Media = ({ data, gs = {} }) => {
  const [current, setCurrent] = useState(0)
  const [swiperRef, setSwiperRef] = useState(null)
  const settings = {
    loop: true,
    speed: 500,
    spaceBetween: 1,
    slidesPerView: 1,
    modules: [Pagination, Autoplay],
    slideToClickedSlide: true,
    centeredSlides: true,
    pagination: {
      clickable: true,
    },
    autoplay: {
      enabled: true,
      delay: 10000,
    },
  }
  return (
    <div
      className={cn(
        {
          [styles.media_content_box_all]: data?.topPadding,
        },
        gs?.media_content_box_all
      )}
    >
      {data?.title && (
        <SectionTitle
          subTitle={data?.subTitle}
          title={data?.title}
          className={cn(styles.title_box, gs?.title_box)}
          cns={{
            subTitle: cn(styles.subTitle, gs?.subTitle),
          }}
          gs={{
            titleBoxPadding: gs?.titleBoxPadding,
          }}
        />
      )}

      <section
        className={cn(
          styles.media_layer,
          { [styles.media_content_nopad]: data?.hasPadding },
          'layer',
          gs?.media_layer
        )}
      >
        <div className={cn(styles.media_content, 'content')}>
          <div
            className={cn(
              {
                [styles.media_content_all]: data.iconList.length <= 5,
              },
              styles.media_content_box_all_grid,
              gs?.media_content_box_all_grid
            )}
          >
            <p
              className={styles.media_content_all_title}
              dangerouslySetInnerHTML={{
                __html: data.titleInit,
              }}
            ></p>
            {data?.txtList && (
              <div className={cn(styles.media_content_box_six, 'text-center')}>
                <div className={styles.txtListCon}>
                  <Swiper
                    {...settings}
                    className={styles.swiper}
                    onSwiper={setSwiperRef}
                    onSlideChange={(swiper) => setCurrent(swiper.realIndex)}
                  >
                    {data?.txtList?.map((item, index) => {
                      return (
                        <div
                          key={`txtItem-${index}`}
                          className={cn(styles.txtItem, {
                            ['!opacity-[1]']: current == index,
                          })}
                        >
                          <SwiperSlide className="l:flex l:h-full l:flex-col">
                            <p
                              key={index}
                              className={styles.media_content_all_title}
                              dangerouslySetInnerHTML={{
                                __html: item?.txt,
                              }}
                            ></p>
                            <div className="mt-[10px] hidden text-center md:block">
                              <Picture
                                className={cn(
                                  styles.media_content_icon,
                                  '!mx-auto'
                                )}
                                source={item?.icon}
                              />
                            </div>
                          </SwiperSlide>
                        </div>
                      )
                    })}
                  </Swiper>
                </div>
                <div className={styles.txtListNav}>
                  {data?.txtList?.map((item, index) => (
                    <div
                      key={`txt-${index}`}
                      onClick={() => {
                        if (!!swiperRef) {
                          setCurrent(index)
                          swiperRef?.slideToLoop(index)
                        }
                      }}
                      className={cn(
                        { [styles.active]: current == index },
                        styles.txtListNavItem
                      )}
                    >
                      <Picture
                        className={cn(styles.media_content_icon)}
                        source={item?.icon}
                      />
                      <div
                        className={styles.txtListNavItemBar}
                        style={{
                          background:
                            gs?.txtListNavItemBar || 'var(--anker-color)',
                        }}
                      ></div>
                    </div>
                  ))}
                </div>
              </div>
            )}
            <div
              className={cn(
                styles.media_content_all_grid,
                gs?.media_content_all_grid
                // { [styles.media_content_box]: data.iconList.length >= 5 },
                // {
                //   [styles.media_content_box_four]:
                //     data.iconList.length <= 4 || data.iconList.length >= 6,
                // },
                // { [styles.media_content_box_five]: data.iconList.length == 5 },
                // {
                //   [styles.media_content_box_six]: data.iconList.length == 6,
                // }
              )}
            >
              {data.iconList &&
                data.iconList.map((v, i) => (
                  <Picture
                    source={v.icon}
                    key={i}
                    className={cn(styles.media_content_icon)}
                  />
                ))}
            </div>
          </div>
        </div>
      </section>
    </div>
  )
}
export default Media

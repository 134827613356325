import { AdvancedVideo, lazyload } from '@cloudinary/react'
import { Cloudinary } from '@cloudinary/url-gen'
import cn from 'classnames'
import Script from 'next/script'
import styles from './Quiz.module.css'
import { scale } from '@cloudinary/url-gen/actions/resize'
import { useEffect, useRef, useState } from 'react'
import Button from '@components/control/Button'
import { ScrollTrigger } from 'gsap/dist/ScrollTrigger'
import { gsap } from 'gsap/dist/gsap'
import { PlayIcon, XMarkIcon } from '@heroicons/react/24/outline'
import Player from '@vimeo/player'
import throttle from 'lodash.throttle'
import SolixButton from '@components/control/SolixButton'
import { useRelativeLink } from '@commerce/product/use-relative-link'

// let data = {
//   "title": " Then try our product classification",
//   "btn": "Take The Quiz Now",
//   "videoId": "anker/home-charger/home-charger",
//   "videoIdMob": "anker/1780/kv_uk_mob"
// }

const Quiz = ({ data = {}, pageHandle, pageType = '' }) => {
  const QuizBox = useRef(null)
  const videoBox = useRef(null)
  const [videoId, setVideoId] = useState()
  const [dialogWrapShow, setDialogWrapShow] = useState(false)
  const [screenWidthBox, setScreenWidthBox] = useState()
  const { setRelativeLink } = useRelativeLink()
  const cldVideoInit = (width) => {
    const cld = new Cloudinary({
      cloud: {
        cloudName: 'anker-dtc',
      },
      url: {
        source: true,
      },
    })
    let myVideo =
      width > 769 ? cld.video(data.videoId) : cld.video(data.videoIdMob)
    myVideo.resize(scale().width(window.screen.width))
    setVideoId(myVideo)
  }
  const resizeWindow = () => {
    const offsetWidth = document.querySelector('body').offsetWidth
    cldVideoInit(offsetWidth)
    setScreenWidthBox(offsetWidth)
  }
  useEffect(() => {
    window.addEventListener('resize', throttle(resizeWindow, 500))
    return () => window.removeEventListener('resize', resizeWindow)
  }, [])

  useEffect(() => {
    const offsetWidth = document.querySelector('body').offsetWidth
    cldVideoInit(offsetWidth)
  }, [])

  const YouTubeVideo = ({ data }) => {
    let playerEvent
    const onPlayerReady = (event) => {
      playerEvent = event.target
      event.target.playVideo()
    }
    const [videoIdDialog, setVideoIdDialogd] = useState()
    const onYouTubeIframeAPIReady = () => {
      if (YT && YT.Player) {
        let players = new YT.Player('player', {
          height: '100%',
          width: '100%',
          videoId: data.videoIdDialog,
          events: {
            onReady: onPlayerReady,
          },
        })
      }
    }
    useEffect(() => {
      onYouTubeIframeAPIReady()
    }, [])
    return (
      <div className={styles.dialogWrap}>
        <XMarkIcon
          className={styles.closeWrap}
          onClick={() => {
            setDialogWrapShow(false)
          }}
        />
        <div id="player"></div>
      </div>
    )
  }

  const watchVideoClick = () => {
    setDialogWrapShow(true)
    dataLayer.push({
      event: 'uaEvent',
      eventCategory: 'video',
      eventAction: 'start',
      eventLabel: data?.title,
      nonInteraction: false,
    })
    window.dataLayer.push({ event_parameters: null })
    window.dataLayer.push({
      event: 'ga4Event',
      event_name: 'lp_button',
      event_parameters: {
        page_group: 'Activity Page_' + pageHandle,
        position: data?.title,
        button_name: 'video',
      },
    })
  }

  return (
    <div ref={QuizBox}>
      <Script
        id="youtube-iframe-api"
        strategy="lazyOnload"
        dangerouslySetInnerHTML={{
          __html: `var tag = document.createElement('script'); tag.src
                ='https://www.youtube.com/iframe_api'; var firstScriptTag =
                document.getElementsByTagName('script')[0];
                firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);`,
        }}
      />
      {dialogWrapShow && <YouTubeVideo data={data} />}
      <div className={cn('bg-black', 'relative', styles.quizBox_all)}>
        <div ref={videoBox} className={cn(styles.videoBox)}>
          {videoId && (
            <>
              <AdvancedVideo
                cldVid={videoId}
                muted
                autoPlay
                preload="none"
                playsInline
                loop
                poster={data.poster}
                x5-video-player-fullscreen="true"
                plugins={[lazyload()]}
                className="block w-full md:hidden"
                style={{ width: screenWidthBox }}
              />
              <AdvancedVideo
                cldVid={videoId}
                muted
                autoPlay
                preload="none"
                playsInline
                loop
                x5-video-player-fullscreen="true"
                poster={data.poster_mob}
                plugins={[lazyload()]}
                className="hidden w-full md:block"
                width={390}
                height={600}
                style={{ width: screenWidthBox }}
              />
            </>
          )}
        </div>
        <div className={cn(styles.quizBox_content)}>
          {data?.title && (
            <p
              className={styles.quizBox_content_title}
              dangerouslySetInnerHTML={{
                __html: data?.title,
              }}
            ></p>
          )}
          <div className="flex gap-[30px] md:flex-col md:gap-[10px]">
            {data?.btn &&
              (pageType === 'hes' || pageType === 'powerStation' ? (
                <SolixButton
                  border
                  small
                  theme={'dark'}
                  className={styles.product_view_btn}
                  onClick={() => {
                    watchVideoClick()
                  }}
                >
                  <div className="flex justify-center text-[16px]">
                    {data?.btn}
                    <PlayIcon className="ml-[10px] w-[11px] fill-white md:ml-[5px]" />
                  </div>
                </SolixButton>
              ) : (
                <Button
                  border
                  small
                  theme={'dark'}
                  className={styles.product_view_btn}
                  onClick={() => {
                    watchVideoClick()
                  }}
                >
                  <div className="flex justify-center text-[16px]">
                    {data?.btn}
                    <PlayIcon className="ml-[10px] w-[11px] fill-white md:ml-[5px]" />
                  </div>
                </Button>
              ))}
            {data?.link &&
              (pageType === 'hes' || pageType === 'powerStation' ? (
                <Button
                  href={setRelativeLink({ link: data?.link })}
                  border
                  small
                  theme={'dark'}
                  className={styles.product_view_btn}
                  variant="link"
                >
                  {data?.learnMore}
                </Button>
              ) : (
                <SolixButton className={styles.solixBtn}>
                  <a
                    className="block"
                    href={`${setRelativeLink({ link: data?.link })}`}
                  >
                    {data.learnMore}
                  </a>
                </SolixButton>
              ))}
          </div>
        </div>
      </div>
    </div>
  )
}
export default Quiz
